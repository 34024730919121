<template>
  <div class="judgespage">
    <div class="headimg">透析专项规范化操作技能竞赛</div>
    <div class="content">
      <div class="left">
        <div class="infobox">
          <div class="title">
            <span>
              <img src="../../assets/image/pingwei/icon1.png" alt="" />
              个人信息
            </span>
            <span id="set" @click="setUserInfoChange()">编辑</span>
          </div>
          <ul class="infocontent">
            <li>
              <span>姓名：</span>
              <p>{{ JudgesForm.username }}</p>
            </li>
            <li>
              <span>电话：</span>
              <p>{{ JudgesForm.mobile }}</p>
            </li>
            <li>
              <span>地区：</span>
              <p>
                <i>{{
                  JudgesForm.province_name ? JudgesForm.province_name : ""
                }}</i
                >- <i>{{ JudgesForm.city_name ? JudgesForm.city_name : "" }}</i
                >-
                <i>{{
                  JudgesForm.district_name ? JudgesForm.district_name : ""
                }}</i>
              </p>
            </li>
            <li>
              <span>医院：</span>
              <p>{{ JudgesForm.hospital }}</p>
            </li>
            <li>
              <span>职称：</span>
              <p>{{ JudgesForm.zhicheng }}</p>
            </li>
            <li>
              <span>组别：</span>
              <p>{{ JudgesForm.cszb }}</p>
            </li>
          </ul>
        </div>
        <div class="infobox" v-if="JudgesForm.auth == 1||JudgesForm.auth == 3">
          <div class="title">
            <span>
              <img src="../../assets/image/pingwei/icon2.png" alt="" />
              作品信息
            </span>
          </div>
          <ul class="infocontent">
            <li>
              <span class="span2">总数量：</span>
              <p class="p2">{{ OpusCount.totalCount }}</p>
            </li>
            <li>
              <span class="span2">未评分：</span>
              <p class="p2">{{ OpusCount.wpfCount }}</p>
            </li>
            <li>
              <span class="span2">已评分：</span>
              <p class="p2">{{ OpusCount.ypfCount }}</p>
            </li>
          </ul>
        </div>
        <div class="infobox" v-if="JudgesForm.auth > 1">
          <div class="title">
            <span>
              <img src="../../assets/image/pingwei/icon2.png" alt="" />
              作品信息(本组)
            </span>
          </div>
          <ul class="infocontent">
            <li>
              <span class="span2">总数量：</span>
              <p class="p2">{{ OpusCount.totalCount_all }}</p>
            </li>
            <li>
              <span class="span2">未评分：</span>
              <p class="p2">{{ OpusCount.wpfCount_all }}</p>
            </li>
            <li>
              <span class="span2">已评分：</span>
              <p class="p2">{{ OpusCount.ypfCount_all }}</p>
            </li>
          </ul>
        </div>
        <div class="infobox">
          <div class="title boderNone">
            <span>
              <img src="../../assets/image/pingwei/icon3.png" alt="" />
              评分标准
            </span>
          </div>
          <div class="scoreBox">
            <div class="box-title">
              <span>评分表</span>
              <span>总分100分</span>
            </div>
            <ul class="box-table">
              <li
                @click="tableDialog()"
                v-for="item in ScoreList"
                :key="item.id"
              >
                <span class="span1">{{ item.name }}</span>
                <span class="span2">{{ item.score }}</span>
                <span class="span3">></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 菜单 -->
        <div class="mymenu">
          <ul class="menulist" v-if="menuShow">
            <li
              :id="menugl == item.name ? 'menugl' : ''"
              v-for="item in menuList"
              :key="item.id"
              @click="menuChange(item.name)"
            >
              {{ item.text }}（{{ item.num }}）
            </li>
          </ul>
          <div class="logOut" @click="logOut()">退出登录</div>
        </div>
        <!-- 内容 -->
        <router-view @updateCount="getOpusCountHandle"></router-view>
        <!-- 编辑信息对话框 -->
        <div class="dialog1">
          <el-dialog
            title="专家填写信息"
            :visible.sync="JudgesInfoShow"
            width="58%"
            :show-close="false"
            :close-on-click-modal="false"
          >
            <div class="form">
              <el-form
                label-position="left"
                :model="JudgesForm"
                :rules="rules"
                ref="JudgesForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item
                  label="专家姓名："
                  prop="username"
                  placeholder="请输入您的姓名"
                >
                  <el-input
                    v-model="JudgesForm.username"
                    class="inputWidth"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="电话号码："
                  prop="mobile"
                  placeholder="请输入您的电话号码"
                >
                  <el-input
                    v-model="JudgesForm.mobile"
                    class="inputWidth"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="地区选择:" prop="district">
                  <div class="adress">
                    <el-select
                      v-model="JudgesForm.province"
                      placeholder="请选择省份"
                      @change="provinceChange()"
                    >
                      <el-option
                        v-for="item in mapList.province"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="JudgesForm.city"
                      placeholder="请选择城市"
                      @change="cityChange()"
                    >
                      <el-option
                        v-for="item in mapList.city"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="JudgesForm.district"
                      placeholder="请选择区域"
                    >
                      <el-option
                        v-for="item in mapList.area"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="医院全称:" prop="hospital">
                  <el-input
                    v-model="JudgesForm.hospital"
                    class="inputWidth"
                    @input="hospitalChange()"
                    placeholder="请输入您的医院名称"
                  ></el-input>
                  <div
                    class="hospitalList"
                    v-if="hospitalListIsShow"
                    v-loadMore="hospitalDel"
                  >
                    <ul class="hospitalListMore">
                      <li
                        v-for="item in mapList.hospital"
                        :key="item.id"
                        @click="choiceHospital(item)"
                      >
                        <p>{{ item.name }}</p>
                      </li>
                    </ul>
                  </div>
                </el-form-item>
                <el-form-item label="科室选择:" prop="department_id">
                  <el-radio-group v-model="JudgesForm.department_id">
                    <el-radio
                      :label="item.id"
                      v-for="item in DepartmentList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="职称选择:" prop="zhicheng_id">
                  <el-radio-group v-model="JudgesForm.zhicheng_id">
                    <el-radio
                      :label="item.id"
                      v-for="item in zhichengList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitForm('JudgesForm')"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!-- 编辑信息对话框 -->
        <!-- 评分详情对话框 -->
        <div class="dialog2">
          <el-dialog
            title="评分标准 【总分100分】"
            :visible.sync="scoreRuleDialog"
            v-if="scoreRuleDialog"
            width="60%"
            :before-close="
              () => {
                scoreRuleDialog = false;
              }
            "
          >
            <scoreruleMod :ScoreList="ScoreList" :cszb="cszb"></scoreruleMod>
          </el-dialog>
        </div>
        <!-- 评分详情对话框 -->
      </div>
    </div>
  </div>
</template>

<script>
import { getAreas, searchHospital } from "../API/city";
import { getJudgesInfo, getOpusCount, editJudgesInfo } from "../JudegeAPI/user";
import { getTokenJ, removeTokenJ } from "../../assets/js/Storage-J";
import { scoringRule } from "../JudegeAPI/Score";
import { judgesLogout } from "../JudegeAPI/login";
import scoreruleMod from "../../components/scoreruleMod.vue";
export default {
  components: {
    scoreruleMod,
  },
  data() {
    return {
      cszb: 0, //参赛组别
      ScoreList: [], //评分规则
      // 表评委信息表单
      JudgesForm: {
        username: "",
        mobile: "",
        province: "", //省
        city: "", //市
        district: "", //区
        hospital: "",
        hospital_id: "",
        department_id: "",
        zhicheng_id: "",
      },
      OpusCount: {},
      OpusCountAll: {},
      // 地图列表
      mapList: {
        province: [],
        city: [],
        area: [],
        hospital: [],
      },
      hospitalListIsShow: false, //医院列表是否显示
      JudgesInfoShow: false,
      menuShow:
        this.$router.history.current.name !== "videoDetails" &&
        this.$router.history.current.name !== "scoreDetails",
      // 请求医院页码
      pageSize: {
        page: 1,
        limit: 10,
      },
      count: 0, //医院总数
      // 科室列表
      DepartmentList: [
        {
          id: 0,
          name: "请选择科室",
        },
      ],
      // 职称列表
      zhichengList: [
        {
          id: 0,
          name: "请选择职称",
        },
      ],
      //  菜单
      menuList: [],
      scoreRuleDialog: false, //评分标准对话框
      menugl: "",
      rules: {
        // 姓名
        username: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          {
            min: 2,
            max: 8,
            message: "长度应在 2 到 8 个字符",
            trigger: "blur",
          },
        ],
        // 电话
        mobile: [
          { required: true, message: "请输入您的电话", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的电话号码",
            trigger: "blur",
          },
        ],
        // 地区
        district: [
          { required: true, message: "请选择您的区域", trigger: "blur" },
        ],
        // 医院
        hospital: [
          { required: true, message: "请选择您的医院", trigger: "blur" },
        ],
        // 科室
        department_id: [
          { required: true, message: "请选择您的科室", trigger: "change" },
        ],
        // 职称
        zhicheng_id: [
          { required: true, message: "请选择您的职称", trigger: "change" },
        ],
      },
    };
  },
  async created() {
    if (document.body.clientWidth < 540) {
      this.$message.error("请在PC端访问评委页面");
      return this.$router.push({ name: "Judges" });
    }
    await this.getProvince(0); //获取省份信息
    await this.getJudgesInfoHandle(getTokenJ().j_id); //获取评委信息
    await this.getOpusCountHandle(); //获取作品数量
    await this.getScoreList(); // 获取预览打分列表
    this.$router.push({ name: this.menugl });
  },
  mounted() {},
  methods: {
    // 退出登录
    async logOut() {
      const { data: res } = await judgesLogout();
      if (res.code !== "0000") {
        return this.$message.error("退出登录失败");
      }
      removeTokenJ();
      localStorage.removeItem("Authorization");
      this.$router.push({ name: "Judges" });
      this.$message.success("退出登录成功");
    },
    // 获取预览打分列表
    async getScoreList() {
      const res = await scoringRule({
        j_id: getTokenJ().j_id,
        phase: localStorage.getItem("phase"),
      });
      this.cszb = res.data.data.cszb;
      localStorage.cszb = res.data.data.cszb
      if (res.data.data.rules) {
        this.ScoreList = res.data.data.rules;
      }
    },

    // 评分详情对话框
    tableDialog() {
      this.scoreRuleDialog = true;
    },
    // 获取评委作品数量
    async getOpusCountHandle() {
      const { data: res } = await getOpusCount({
        j_id: getTokenJ().j_id,
        phase: localStorage.getItem("phase"),
      });
      if (getTokenJ().auth == 1) {
        this.menuList = [
          {
            id: 1,
            name: "noScore",
            text: "未评分作品",
            num: res.data.wpfCount,
          },
          {
            id: 2,
            name: "yesScore",
            text: "已评分作品",
            num: res.data.ypfCount,
          },
        ];
      } else if (getTokenJ().auth == 3) {
        this.menuList = [
          {
            id: 1,
            name: "noScore",
            text: "未评分作品",
            num: res.data.wpfCount,
          },
          {
            id: 2,
            name: "yesScore",
            text: "已评分作品",
            num: res.data.ypfCount,
          },
          {
            id: 3,
            name: "allScore",
            text: "本组全部",
            num: res.data.totalCount_all,
          },
        ];
      } else {
        this.menuList = [
          {
            id: 3,
            name: "allScore",
            text: "本组全部",
            num: res.data.totalCount_all,
          },
        ];
      }
      this.OpusCount = res.data;
      this.menugl = this.menuList[0].name;
    },

    // 获取评委信息
    async getJudgesInfoHandle(id) {
      const { data: res } = await getJudgesInfo({ j_id: id });
      if (!res.data.judge) {
        this.DepartmentList = res.data.department;
        this.zhichengList = res.data.zhicheng;
        return;
      }
      this.JudgesForm = res.data.judge;
      this.DepartmentList = res.data.department;
      this.zhichengList = res.data.zhicheng;
      for (const key in this.JudgesForm) {
        if (this.JudgesForm[key] == null) {
          this.JudgesForm[key] = "";
        }
      }
      for (const key in this.JudgesForm) {
        if (this.JudgesForm[key] == 0) {
          this.JudgesForm[key] = "";
        }
      }
      if (
        this.JudgesForm.district == "" ||
        this.JudgesForm.hospital == "" ||
        this.JudgesForm.department_id == "" ||
        this.JudgesForm.zhicheng_id == ""
      ) {
        this.JudgesInfoShow = true;
      }
    },
    // 评委信息提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let newObj = { ...this.JudgesForm };
          if (this.count === 0) {
            newObj.hospital_id = 0;
          }
          if (this.hospitalListIsShow) {
            return this.$message("请选择您的医院");
          }
          delete newObj.province_name;
          delete newObj.city_name;
          delete newObj.district_name;
          delete newObj.cszb;
          const { data: res } = await editJudgesInfo(newObj);
          if (res.code !== "0000") {
            this.JudgesInfoShow = false;
            return this.$message(res.msg);
          }
          this.$message.success("修改成功");
          this.getJudgesInfoHandle(getTokenJ().j_id);
          this.JudgesInfoShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取城市信息
    async getProvince(id) {
      const { data: res } = await getAreas(id);
      if (res.code == "0000") {
        this.mapList.province = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    // 选择省/市信息
    provinceChange() {
      this.JudgesForm.city = "";
      this.JudgesForm.district = "";
      this.mapList.city = [];
      this.mapList.area = [];
    },
    cityChange() {
      this.JudgesForm.district = "";
      this.mapList.area = [];
    },
    // 菜单切换
    menuChange(name) {
      this.menugl = name;
      this.$router.push({ name }).catch((err) => err);
    },
    // 编辑用户信息
    setUserInfoChange() {
      this.JudgesInfoShow = true;
    },
    // 获取医院列表
    async gethospital(data) {
      const { data: res } = await searchHospital(data);
      this.mapList.hospital = res.data.list;
      this.count = res.data.count;
      if (this.count === 0) {
        this.hospitalListIsShow = false;
        this.JudgesForm.hospital_id = 0;
      }
    },
    // 输入医院
    hospitalChange() {
      this.hospitalListIsShow = true;
      this.gethospital({
        ...this.pageSize,
        hospital_name: this.JudgesForm.hospital,
      });
    },
    // 选择医院
    choiceHospital(item) {
      this.JudgesForm.hospital = item.name;
      this.JudgesForm.hospital_id = item.id;
      this.hospitalListIsShow = false;
    },
    // 医院列表触底
    async hospitalDel() {
      if (this.mapList.hospital.length < this.count) {
        this.pageSize.page++;
        // 获取医院信息
        const { data: res } = await searchHospital({
          hospital_name: this.JudgesForm.hospital,
          ...this.pageSize,
        });
        if (res.code == "0000") {
          this.mapList.hospital.push(...res.data.list);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      } else {
        return;
      }
    },
  },
  //
  watch: {
    async $route(to, from) {
      let newpath = to.path.slice(1).split("/")[1];
      if (newpath == "noScoreDetails" || newpath == "yesScoreDetails") {
        this.menuShow = false;
      }
      if (
        newpath == "yesScore" ||
        newpath == "noScore" ||
        newpath == "allScore"
      ) {
        this.menuShow = true;
      }
      if (from.name === "noScoreDetails") {
        await this.getOpusCountHandle();
        this.menugl = newpath;
      }
    },
    "JudgesForm.province": async function (newV) {
      if (newV == "") {
        return;
      }
      // 获取市信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.city = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "JudgesForm.city": async function (newV) {
      if (newV == "") {
        return;
      }
      // 获取区信息
      const { data: res } = await getAreas(newV);
      if (res.code == "0000") {
        this.mapList.area = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    "JudgesForm.hospital": function (newV) {
      if (newV == "") {
        this.hospitalListIsShow = false;
      }
    },
    "mapList.hospital": function (newV) {
      try {
        if (newV.length < 1) {
          this.hospitalListIsShow = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    "JudgesForm.zhicheng_id": function (newV) {
      const newArr = this.zhichengList.filter((item) => {
        return item.id == newV;
      });
      if (newArr.length < 1) return;
      this.JudgesForm.zhicheng = newArr[0].name;
    },
  },
};
</script>

<style scoped lang="scss">
.judgespage {
  .el-radio {
    padding: 5px 0;
  }
  .diqu {
    font-size: 16px;
  }
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #5b2d9b;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    background-color: #5b2d9b;
    border-color: #5b2d9b !important;
  }
  .adress {
    .el-select {
      margin-right: 10px;
      width: 195px;
    }
  }
  .inputWidth {
    width: 400px;
  }
  .hospitalList {
    position: absolute;
    left: 0;
    top: 45px;
    z-index: 999;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid #e4e7ed;
    width: 400px;
    padding: 10px 0;
    background-color: #fff;
    @media screen and (max-width: 540px) {
      width: 280px;
    }
    ul {
      height: 280px;
      overflow-y: scroll;
      li {
        box-sizing: border-box;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          padding: 5px 0;
        }
        p {
          padding: 0 15px;
          @media screen and (max-width: 540px) {
            line-height: 25px;
          }
        }
      }
      li:hover {
        background-color: #eee;
      }
    }
  }
  .dialog1 {
    ::v-deep .el-dialog {
      border-radius: 12px;
    }
    ::v-deep .el-dialog__header {
      text-align: center;
      box-sizing: border-box;
      padding: 30px 0;
      background: url("../../assets/image/login_bg.webp") no-repeat;
      background-size: 100% 100%;
      span {
        color: #5b2d9b;
        font-size: 26px;
      }
    }
    ::v-deep .el-dialog__body {
      padding: 20px 100px;
    }
    ::v-deep .el-dialog__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      .dialog-footer {
        .el-button {
          width: 140px;
          background-color: #293f8e;
          color: #fff;
        }
      }
    }
  }
  .dialog2 {
    ::v-deep .el-dialog__header span {
      display: block;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 20px;
    }
    ::v-deep .el-table__row td:last-child {
      padding: 0;
    }
    ::v-deep .el-dialog__body {
      height: 500px;
      overflow-y: scroll;
    }
    .GradeList {
      display: flex;
      li {
        flex: 1;
      }
    }
  }
  .boderNone {
    border: none !important;
  }
  .headimg {
    height: 100px;
    width: 100%;
    background: url("https://jnds-static.oss-cn-beijing.aliyuncs.com/judge-bg.png")
      no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 100px;
    color: #5b2d9b;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  .content {
    background-color: #f7f7f7;
    padding: 30px;
    display: flex;
    justify-content: center;
    .left {
      width: 20%;
      padding-right: 30px;
      .infobox {
        padding: 20px;
        background-color: #ffffff;
        margin-bottom: 6px;
        width: 100%;
        box-sizing: border-box;
        .title {
          padding: 0 0 10px 0;
          color: #5b2d9b;
          font-weight: 700;
          margin-bottom: 10px;
          font-size: 18px;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: space-between;
          img {
            display: block;
            width: 27px;
            height: 27px;
            margin-right: 12px;
          }
          span {
            display: flex;
            align-items: center;
          }
          #set {
            color: #333333;
            cursor: pointer;
            font-weight: 500;
          }
        }
        .infocontent {
          li:last-child {
            border: none;
          }
          li {
            margin: 0 30px;
            padding: 10px 0;
            border-bottom: 1px solid #eeeeee;
            font-size: 18px;
            color: #333333;
            display: flex;
            align-items: center;
            .span2 {
              display: block;
              padding-right: 10px;
              color: #999999;
              width: 80px;
            }
            .p2 {
              flex: 1;
              font-size: 16px;
              overflow: hidden;
            }
            i {
              font-style: normal;
            }
            span {
              display: block;
              padding-right: 10px;
              color: #999999;
              width: 60px;
            }
            p {
              flex: 1;
              font-size: 16px;
              overflow: hidden;
            }
          }
        }
        .scoreBox {
          border: 1px solid #eeeeee;
          border-radius: 5px;
          width: 100%;
          font-size: 18px;
          .box-title {
            height: 40px;
            background-color: #f7f8fa;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              padding-right: 14px;
              font-weight: 700;
            }
          }
          .box-table {
            cursor: pointer;
            padding: 10px 20px;
            li:last-child {
              border: none;
            }
            li {
              height: 40px;
              line-height: 40px;
              display: flex;
              border-bottom: 1px solid #e7e7e7;
              span {
                display: block;
              }
              .span1 {
                width: 68%;
                font-size: 17px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .span2 {
                width: 20%;
                text-align: center;
                padding-right: 10px;
              }
              .span3 {
                color: #999999;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .right {
      width: 80%;
      .mymenu {
        background-color: #ffffff;
        position: relative;
        .logOut {
          position: absolute;
          right: 36px;
          top: 30px;
          padding: 5px 10px;
          border-radius: 3px;
          background-color: #5b2d9b;
          color: #fff;
          font-size: 15px;
          cursor: pointer;
        }
        .menulist {
          padding-top: 30px;
          border-bottom: 3px solid #dcdcdc;
          margin: 0 30px;
          display: flex;
          li {
            padding: 0 5px;
            margin-right: 30px;
            padding-bottom: 18px;
            cursor: pointer;
          }
          #menugl {
            color: #5b2d9b;
            font-weight: 600;
            position: relative;
          }
          #menugl::after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #5b2d9b;
          }
        }
      }
    }
  }
}
</style>
