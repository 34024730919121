<template>
  <div class="rulemod">
    <div class="jishi" v-if="cszb === 5 || cszb === 3">
      <el-table
        border
        class="tableheader"
        :data="ScoreList"
        style="width: 100%"
      >
        <el-table-column
          header-align="center"
          align="center"
          label="项目"
          width="140"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="分值"
          width="100"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="left"
          label="技术操作要求"
        >
        </el-table-column>
        <el-table-column header-align="center" align="center" label="评分等级">
          <el-table-column
            header-align="center"
            align="center"
            label="A"
            width="50"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="B"
            width="50"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="C"
            width="50"
          >
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="tablebody">
        <el-table
          border
          :data="ScoreList"
          :show-header="false"
          style="width: 100%"
        >
          <el-table-column
            header-align="center"
            align="center"
            prop="name"
            label="项目"
            width="140"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="score"
            label="分值"
            width="100"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="left"
            label="技术操作要求"
          >
            <template slot-scope="{ row }">
              <el-table :data="row.children" border :show-header="false">
                <el-table-column
                  class="tablecell"
                  header-align="center"
                  align="center"
                  prop="name"
                  label="项目"
                >
                  <template slot-scope="{ row }">
                    <p class="tablecell">{{ row.name }}</p>
                  </template>
                </el-table-column>
                <el-table-column
                  class="tablecell"
                  header-align="center"
                  align="center"
                  label="A"
                  width="50"
                >
                  <template slot-scope="{ row }">
                    {{ row.score[0] }}
                  </template>
                </el-table-column>
                <el-table-column
                  class="tablecell"
                  header-align="center"
                  align="center"
                  label="B"
                  width="50"
                >
                  <template slot-scope="{ row }">
                    {{ row.score[1] }}
                  </template>
                </el-table-column>
                <el-table-column
                  class="tablecell"
                  header-align="center"
                  align="center"
                  label="C"
                  width="50"
                >
                  <template slot-scope="{ row }">
                    {{ row.score[2] }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="futou" v-if="cszb === 4">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :span-method="objectSpanMethod"
      >
        <el-table-column
          prop="jieduan"
          align="center"
          label="评分阶段"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="xiangmu"
          align="center"
          label="评分项目"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="name" align="center" label="评分标准">
        </el-table-column>
        <el-table-column prop="score" align="center" label="分值" width="100">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.score) ? row.score+'' : row.score }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="jishi" v-if="cszb === 6">
      <el-table border class="tableheader" :data="[]" style="width: 100%">
        <el-table-column
          header-align="center"
          align="center"
          label="项目"
          width="300"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="技术操作要求"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="分值"
          width="100"
        >
        </el-table-column>
      </el-table>
      <el-table
        :data="tableData"
        style="width: 100%"
        :show-header="false"
        border
        :span-method="objectSpanMethod"
      >
        <el-table-column prop="jieduan" align="center" label="项目" width="150">
        </el-table-column>
        <el-table-column
          prop="xiangmu"
          align="center"
          label="评分项目"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="name" align="center" label="技术操作要求">
        </el-table-column>
        <el-table-column prop="score" align="center" label="分值" width="100">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.score) ? row.score+'' : row.score }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cszb: {
      default: 0,
    },
    ScoreList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      copyScoreList: [],
      colFields: ["jieduan", "xiangmu", "name", "score"],
      spanArr: [], //存储合并单元格的开始位置
      // 表格数据
      tableData: [],
    };
  },
  created() {
    if (this.cszb === 4 || this.cszb === 6) {
      this.filterScoreList();
    }
  },
  mounted() {
    this.getSpanArr();
  },
  methods: {
    getSpanArr() {
      for (let i = 0; i < this.tableData.length; i++) {
        let row = i;
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            //  合并所有列的相同数据单元格
            if (
              this.colFields[j] == "jieduan" ||
              this.colFields[j] == "xiangmu"
            ) {
              // 指定合并哪些列
              if (
                this.tableData[row][this.colFields[j]] ===
                this.tableData[row - 1][this.colFields[j]]
              ) {
                let beforeItem =
                  this.spanArr[(row - 1) * this.colFields.length + j];
                this.spanArr[row * this.colFields.length + j] = {
                  rowspan: 1 + beforeItem.rowspan,
                  colspan: 1,
                };
                beforeItem.rowspan = 0;
                beforeItem.colspan = 0;
              } else {
                // rowspan 和 colspan 都为1表格此单元格不合并
                this.spanArr[row * this.colFields.length + j] = {
                  rowspan: 1,
                  colspan: 1,
                };
              }
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = [];
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < this.tableData.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i]);
              while (stack.length > 0) {
                let pop = stack.pop();
                let len = stack.length;
                this.spanArr[(j - len) * this.colFields.length + i] = pop;
              }
            }
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      return this.spanArr[rowIndex * this.colFields.length + columnIndex];
    },
    // 过滤新数组
    filterScoreList() {
      const NewArry = [];
      this.ScoreList.forEach((item1) => {
        if (item1.children) {
          item1.children.forEach((item2) => {
            if (item2.children) {
              item2.children.forEach((item3) => {
                NewArry.push({
                  ...item3,
                  xiangmu: item2.name,
                  jieduan: item1.name,
                });
              });
            } else {
              NewArry.push({
                ...item2,
                xiangmu: item1.name,
                jieduan: item1.name,
              });
            }
          });
        }
      });
      console.log(NewArry);
      this.tableData = NewArry;
    },
  },
};
</script>
<style scoped lang="scss">
.rulemod {
  .jishi {
    .tableheader {
      ::v-deep .el-table__body-wrapper {
        display: none;
      }
    }
    .tablebody {
      ::v-deep .el-table .cell {
        padding: 0;
      }
      //   ::v-deep .el-table .el-table__cell {
      //     padding: 0;
      //   }
    }
    .tablecell {
      padding: 0 10px;
    }
  }
}
</style>
